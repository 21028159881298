import { TBucketOption, getTimeFormat } from '@marlin/shared/utils-chart';
import {
  createDateString,
  dateAdapter,
  formatDate,
  millisecondsInDay,
  millisecondsInHour,
  millisecondsInMinute,
  millisecondsInMonth,
  millisecondsInWeek,
} from '@marlin/shared/utils-common-date';
import Plotly, { Datum } from 'plotly.js';

export const numberTypeGuard = (value: unknown): value is number => typeof value === 'number';

export const dateStringToTimestamp = (date: Datum): number => {
  switch (typeof date) {
    case 'string':
      return new Date(date).getTime();
    case 'number':
      return date;
    default:
      return 0;
  }
};

const offsetMap = new Map([
  ['minutes', millisecondsInMinute],
  ['hours', millisecondsInHour],
  ['days', millisecondsInDay],
  ['weeks', millisecondsInWeek],
  ['months', millisecondsInMonth],
]);

export const getXaxisOffset = (bucketOption: TBucketOption | '' | undefined, isZoomed: boolean) => {
  if (!bucketOption) return 0;

  const offset = offsetMap.get(bucketOption);

  return offset && isZoomed ? offset / 2 : 0;
};

export const getFormattedDate = (point: Plotly.PlotDatum, bucketOption: TBucketOption | undefined) => {
  const timeFormat = getTimeFormat(bucketOption === 'months' ? 'days' : bucketOption);

  const getFormattedDate = (x: Plotly.Datum) => {
    return formatDate(
      createDateString(typeof x === 'string' ? dateAdapter.date?.(x)?.toDate() : undefined),
      timeFormat
    );
  };

  if (bucketOption === 'weeks') {
    const dateFrom = dateAdapter.date?.(point.x);
    const dateTo = dateFrom?.clone().add(6, 'days');

    return `${formatDate(createDateString(dateFrom?.toDate()), timeFormat)} - ${formatDate(
      createDateString(dateTo?.toDate()),
      timeFormat
    )}`;
  }

  if (bucketOption === 'months') {
    const dateFrom = dateAdapter.date?.(point.x);
    const dateTo = dateFrom?.clone().add(30, 'days');

    return `${formatDate(createDateString(dateFrom?.toDate()), timeFormat)} - ${formatDate(
      createDateString(dateTo?.toDate()),
      timeFormat
    )}`;
  }

  return getFormattedDate(point.x);
};
