import { MarlinTheme } from '@marlin/shared/theme';
import { SkeletonLoader } from '@marlin/shared/ui-loader';
import { AnnotationTooltip, IAnnotation } from '@marlin/shared/ui/annotation-tooltip';
import { EmptyChartData } from '@marlin/shared/ui/chart';
import { IChartSeries, TChartDisplayType, getTimeLineChartData, plotlyAdapterSchema } from '@marlin/shared/utils-chart';
import { CircularProgress, alpha } from '@mui/material';
import Plotly, { Config, Layout, PlotMouseEvent } from 'plotly.js';
import React, { useMemo } from 'react';
import ApexChart from 'react-apexcharts';
import Plot from 'react-plotly.js';
import { makeStyles } from 'tss-react/mui';

import { Tooltip } from './tooltip.component';

const chartDesktopHeight = 360;
const singleRangeBarChartDesktopHeight = 38;
const rangeBarDesktopHeightFactor = 94;
const chartContainerTopMargin = 32;
const apexChartMargin = 16;

interface ITooltipData {
  color: string;
  displayedValue: string;
  name: string;
  formattedDate: string;
  date: string;
}

interface ITooltip {
  top: number;
  left: number;
  tooltipData: ITooltipData[] | null;
}

export interface IChartOptions {
  layout?: Partial<Layout>;
  config?: Partial<Config>;
  handleRelayout?: (e: Plotly.PlotRelayoutEvent) => void;
  handleHover?: (event: Plotly.PlotHoverEvent) => void;
  handleUnhover?: (event: Readonly<PlotMouseEvent>) => void;
  isEmpty?: boolean;
  tooltip?: (Partial<ITooltip> | null) & {
    direction: 'right' | 'left';
    showTooltip: boolean;
  };
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginTop: theme.typography.pxToRem(32),
    position: 'relative',

    'div.modebar-group [data-attr="disabled"]': {
      '&:hover > .icon path': {
        // This important is needed to override the default plotly fill color as plotly uses id selector internally
        fill: 'rgba(68, 68, 68, 0.3) !important',
      },
    },

    '.draglayer.cursor-move': {
      '.nsewdrag.drag.cursor-ew-resize': {
        cursor: 'grab',
      },
    },
  },
  barsChart: {
    height: '100%',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: alpha(theme.palette.background.alternative, 0.7),
    zIndex: theme.zIndex.drawer,
  },

  skeleton: {
    height: '100%',
    width: '100%',
  },
}));

interface IChartWrapperProps extends IChartOptions {
  isLoading: boolean;
  isFetching: boolean;
  chartId: string;
  chartData: IChartSeries[];
  currentAnnotationTooltip: IAnnotation | null;
  to: number;
  from: number;
  chartDisplayType: TChartDisplayType;
  isFullScreen?: boolean;
  headerHeight?: number;
  rangeBarDatapoints?: string[];
  paperHeight?: number;
  className?: string;
  height?: number;
  chartOptions?: ApexCharts.ApexOptions;
}

export const ChartSwitcherWrapper = ({
  isLoading,
  isFetching,
  chartId,
  chartData,
  currentAnnotationTooltip,
  to,
  from,
  chartDisplayType,
  className,
  layout,
  config,
  chartOptions,
  handleRelayout,
  isFullScreen,
  rangeBarDatapoints,
  tooltip,
  handleUnhover,
  handleHover,
  isEmpty,
}: IChartWrapperProps) => {
  const { classes, cx } = useStyles();

  return (
    <SkeletonLoader loading={isLoading} skeletonsCount={5}>
      <div
        className={cx(classes.container, className, {
          [classes.barsChart]: chartDisplayType === 'bar' || chartDisplayType === 'rangeBar',
        })}
        data-testid={chartId}
      >
        {isFetching && !isLoading && (
          <div className={classes.overlay}>
            <CircularProgress />
          </div>
        )}
        <ChartSwitcher
          layout={layout}
          config={config}
          chartData={chartData}
          to={to}
          rangeBarDatapoints={rangeBarDatapoints}
          chartDisplayType={chartDisplayType}
          chartOptions={chartOptions}
          handleRelayout={handleRelayout}
          isFullScreen={isFullScreen}
          handleHover={handleHover}
          handleUnhover={handleUnhover}
          tooltip={tooltip}
          isEmpty={isEmpty}
        />
        {currentAnnotationTooltip && <AnnotationTooltip {...currentAnnotationTooltip} />}

        {!isLoading && <EmptyChartData chartData={chartData} from={from} to={to} chartDisplayType={chartDisplayType} />}
      </div>
    </SkeletonLoader>
  );
};

export const ChartSwitcher = ({
  chartData,
  chartOptions,
  chartDisplayType,
  isFullScreen,
  height,
  to,
  headerHeight = 0,
  rangeBarDatapoints,
  paperHeight = 0,
  layout,
  config,
  handleRelayout,
  handleHover,
  handleUnhover,
  tooltip,
}: Omit<IChartWrapperProps, 'chartId' | 'isLoading' | 'isFetching' | 'currentAnnotationTooltip' | 'from'>) => {
  const data = useMemo(() => plotlyAdapterSchema.parse(chartData), [chartData]);

  if (chartOptions && chartDisplayType === 'rangeBar') {
    const rangeBarDataSeries = rangeBarDatapoints
      ? getTimeLineChartData({
          chartData,
          to,
          possibleDatapoints: rangeBarDatapoints,
        })
      : [];

    const getHeight = () => {
      if (height) {
        return height;
      }

      if (chartData.length && rangeBarDatapoints) {
        return rangeBarDatapoints.length * singleRangeBarChartDesktopHeight + rangeBarDesktopHeightFactor;
      }

      return chartDesktopHeight;
    };

    return (
      <ApexChart
        width="100%"
        height={getHeight()}
        series={rangeBarDataSeries}
        options={chartOptions}
        type="rangeBar"
        key={chartDisplayType}
      />
    );
  }

  const fullChartHeight = paperHeight - headerHeight - chartContainerTopMargin - apexChartMargin;
  if (chartOptions) {
    return (
      <ApexChart
        width="100%"
        height={isFullScreen ? fullChartHeight : chartDesktopHeight}
        series={chartData}
        options={chartOptions}
        type={chartDisplayType}
        key={chartDisplayType}
      />
    );
  }

  if (layout) {
    return (
      <>
        <Plot
          data={data}
          layout={layout}
          config={config}
          useResizeHandler={true}
          style={{
            width: '100%',
            height: '100%',
          }}
          onRelayout={handleRelayout}
          onHover={handleHover}
          onUnhover={handleUnhover}
        />
        <Tooltip {...(tooltip || {})} />
      </>
    );
  }

  return null;
};
