import { MarlinTheme } from '@marlin/shared/theme';
import { isEmptyChartData } from '@marlin/shared/ui/chart';
import {
  IApexAxisChartThresholdSeries,
  IChartSeries,
  RANGE_FILTER,
  TBucketOption,
  TChartDisplayType,
} from '@marlin/shared/utils-chart';
import { createDateString, dateAdapter, formatDate, millisecondsInMinute } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure, getFormattedValue, getUomSuffix } from '@marlin/shared/utils-format-reading';
import { useMediaQuery, useTheme } from '@mui/material';
import get from 'lodash/get';
import { useObservableState } from 'observable-hooks';
import Plotly, { Config, Layout, PlotMouseEvent } from 'plotly.js';
import { useCallback, useMemo, useState } from 'react';
import { BehaviorSubject, debounceTime, filter, map, pairwise, switchMap, timer } from 'rxjs';
import * as z from 'zod';

import { TOOLBAR_ICON_PATH } from './const/toolbar-icon-path';
import { content } from './content';
import { dateStringToTimestamp, getFormattedDate, getXaxisOffset, numberTypeGuard } from './utils/chart-options-utils';
import { getNewSelection, isSelectionGreaterThanTwoHours } from './utils/chart-selection';

const chartTypeValues = ['temperature', 'pressure', 'onOff', 'flow', 'leak', 'custom'] as const;
export const ChartType = z.enum(chartTypeValues);
export type TChartType = z.infer<typeof ChartType>;

interface IChartOptionsParams {
  chartData: IChartSeries[];
  chartDataWithoutMargins?: IChartSeries[];
  thresholdsData?: IApexAxisChartThresholdSeries[];
  rangeFilter: RANGE_FILTER;
  from?: number;
  to?: number;
  max: number;
  min: number;
  handleZoomChange: (zoom?: { min: number; max: number }, bucketOption?: TBucketOption) => void;
  isZoomed: boolean;
  chartDisplayType: TChartDisplayType;
  chartId: string;
  isFullScreen?: boolean;
  onFullScreenClick?: () => void;
  customIconClassName?: string;
  hideUomOnAxis?: boolean;
  additionalAxisUom?: TUnitOfMeasure;
  mainDatapoint?: string;
  bucketOption?: TBucketOption;
  chartType?: TChartType;
}

interface ITooltipData {
  color: string;
  displayedValue: string;
  name: string;
  formattedDate: string;
  date: string;
}

interface ITooltip {
  top: number;
  left: number;
  tooltipData: ITooltipData[] | null;
}

export interface IChartOptions {
  layout: Partial<Layout>;
  config: Partial<Config>;
  handleRelayout: (e: Plotly.PlotRelayoutEvent) => void;
  handleHover: (event: Plotly.PlotHoverEvent) => void;
  handleUnhover: (event: Readonly<PlotMouseEvent>) => void;
  isEmpty: boolean;
  tooltip: (Partial<ITooltip> | null) & {
    direction: 'right' | 'left';
    showTooltip: boolean;
  };
}

export const useChartOptionsV2 = ({
  max,
  min,
  chartData,
  chartDataWithoutMargins,
  thresholdsData = [],
  rangeFilter,
  from,
  to,
  isZoomed,
  handleZoomChange,
  chartDisplayType,
  chartId,
  isFullScreen,
  customIconClassName,
  onFullScreenClick,
  hideUomOnAxis,
  additionalAxisUom,
  mainDatapoint,
  bucketOption,
  chartType,
}: IChartOptionsParams): IChartOptions => {
  const theme = useTheme<MarlinTheme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isEmpty = isEmptyChartData({ chartData, from, to, chartDisplayType });
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltip$ = useMemo(() => new BehaviorSubject<ITooltip | null>(null), []);
  const [dragMode, setDragMode] = useState<
    'zoom' | 'pan' | 'select' | 'lasso' | 'orbit' | 'turntable' | false | undefined
  >(undefined);

  const uom = chartData[0]?.uom;

  const chartHeight = useMemo(() => {
    if (isMobile) {
      return 280;
    }

    if (isFullScreen) {
      return 640;
    }

    return 360;
  }, [isFullScreen, isMobile]);

  const [tooltip] = useObservableState<ITooltip | null>(
    () =>
      tooltip$.pipe(
        debounceTime(10),
        switchMap((tooltip) => {
          if (tooltip !== null) {
            return [tooltip];
          }
          return timer(300).pipe(switchMap(() => [null]));
        })
      ),
    null
  );

  const [tooltipDirection] = useObservableState<'left' | 'right'>(
    () =>
      tooltip$.pipe(
        debounceTime(5),
        filter((value) => !!value),
        pairwise(),
        map(([prev, curr]): 'right' | 'left' => {
          if (!prev || !curr) {
            return 'right';
          }

          const isPrevDateAfterCurrDate = dateAdapter
            ?.date(prev?.tooltipData?.[0].date)
            ?.isAfter(dateAdapter?.date(curr?.tooltipData?.[0].date));

          return isPrevDateAfterCurrDate ? 'right' : 'left';
        }),
        debounceTime(10)
      ),
    'left'
  );
  const setTooltip = useCallback((value: ITooltip | null) => tooltip$.next(value), [tooltip$]);
  const layout: Partial<Layout> = {
    barmode: 'stack',
    hovermode: 'x unified',
    showlegend: false,
    xaxis: {
      type: 'date',
      fixedrange: false,
      autorange: false,
      range:
        from && to
          ? [
              formatDate(createDateString(new Date(from - getXaxisOffset(bucketOption, isZoomed)))),
              formatDate(createDateString(new Date(to - getXaxisOffset(bucketOption, isZoomed)))),
            ]
          : undefined,
    },
    yaxis: {
      fixedrange: true,
      side: 'right',
      range: [min, max],
      ticksuffix: chartType !== 'custom' ? getUomSuffix(uom) : '',
    },
    height: chartHeight,
    margin: {
      t: isMobile ? 40 : 50,
      b: isMobile ? 40 : 50,
      l: isMobile ? 0 : undefined,
      r: isMobile ? 50 : undefined,
    },
    dragmode: dragMode,
  };

  const isFullScreenButton = !isFullScreen && onFullScreenClick;
  const isZoomInButtonEnabled = useMemo(
    () => (from && to ? isSelectionGreaterThanTwoHours(dateStringToTimestamp(from), dateStringToTimestamp(to)) : true),
    [from, to]
  );

  const config: Partial<Config> = useMemo(
    () => ({
      displaylogo: false,
      responsive: true,
      displayModeBar: true,
      modeBarButtons: [
        [
          ...(isZoomInButtonEnabled
            ? ['zoomIn2d' as const]
            : [
                {
                  name: 'disabledZoomIn2d',
                  title: content.DISABLED_MODE_BUTTON,
                  icon: {
                    width: 875,
                    height: 1000,
                    path: TOOLBAR_ICON_PATH.ZOOM_IN,
                    ascent: 850,
                  },
                  click: () => {},
                  attr: 'disabled',
                },
              ]),
          'zoomOut2d',
          ...(isZoomInButtonEnabled
            ? ['zoom2d' as const]
            : [
                {
                  name: 'disabledZoom2d',
                  title: content.DISABLED_MODE_BUTTON,
                  icon: {
                    width: 1000,
                    height: 1000,
                    path: TOOLBAR_ICON_PATH.ZOOM,
                    ascent: 850,
                  },
                  click: () => {},
                  attr: 'disabled',
                },
              ]),
          'pan2d',
          ...(isFullScreenButton
            ? [
                {
                  title: 'Full screen',
                  name: 'autoscale',
                  icon: {
                    width: 1792,
                    path: TOOLBAR_ICON_PATH.FULL_SCREEN,
                    ascent: 1792,
                    descent: 0,
                  },
                  click: () => {
                    onFullScreenClick?.();
                  },
                },
              ]
            : []),
        ],
      ],
    }),
    [isFullScreenButton, isZoomInButtonEnabled, onFullScreenClick]
  );

  const handleRelayout = useCallback(
    (e: Plotly.PlotRelayoutEvent) => {
      const x1 = get(e, 'xaxis.range[0]', null) || get(e, 'xaxis.range', [])[0];
      const x2 = get(e, 'xaxis.range[1]', null) || get(e, 'xaxis.range', [])[1];

      const selectionX1 = dateStringToTimestamp(x1);
      const selectionX2 = dateStringToTimestamp(x2);

      if (selectionX1 && numberTypeGuard(selectionX1) && selectionX2 && numberTypeGuard(selectionX2)) {
        const isFromSelectionChanged = Math.abs(from || 0 - (selectionX1 || 0)) > millisecondsInMinute;
        const isToSelectionChanged = Math.abs((selectionX2 || 0) - (to || 0)) > millisecondsInMinute;

        if (isFromSelectionChanged || isToSelectionChanged) {
          const zoom = getNewSelection(
            { min: selectionX1, max: selectionX2 },
            {
              min: from || 0,
              max: to || 0,
            },
            chartType === 'flow' && chartDisplayType === 'bar'
          );

          handleZoomChange(zoom, bucketOption);
        }
      }
      const newDragMode = get(e, 'dragmode', null);
      if (newDragMode && newDragMode !== dragMode) {
        setDragMode(newDragMode);
      }
    },
    [dragMode, from, to, chartType, chartDisplayType, handleZoomChange]
  );

  const handleHover = useCallback(
    (event: Plotly.PlotHoverEvent) => {
      const points: ITooltipData[] = event.points.map((point): ITooltipData => {
        const uom = point.data.customdata[0] as TUnitOfMeasure;

        return {
          color: point.data.marker.color?.toString() || '',
          displayedValue: getFormattedValue(point.y?.toString() || '', uom).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          name: point.data.name,
          date: typeof point.x === 'string' ? point.x.toString() : '',
          formattedDate: getFormattedDate(point, bucketOption),
        };
      });

      setTooltip({
        top: event.event.clientY,
        left: event.event.clientX,
        tooltipData: points,
      });
      setShowTooltip(true);
    },
    [bucketOption, setTooltip]
  );

  const handleUnhover = useCallback(() => {
    setShowTooltip(false);
    setTooltip(null);
  }, [setTooltip]);

  return {
    layout,
    config,
    handleRelayout,
    isEmpty,
    handleHover,
    handleUnhover,
    tooltip: { ...(tooltip ?? {}), direction: tooltipDirection, showTooltip },
  };
};
